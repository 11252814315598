<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <base-material-card
        color="primary"
        icon="mdi-account"
        inline
        class="px-5 py-3"
      >
        <template v-slot:after-heading>
          <div class="display-2 font-weight-light">
            {{ $t('titleUsers') }}
          </div>
        </template>

        <v-text-field
          v-model="user_search"
          append-icon="mdi-magnify"
          class="ml-auto"
          :label="$t('dataTableSearch')"
          hide-details
          single-line
          style="max-width: 250px;"
        />

        <v-divider class="mt-1" />

        <v-data-table
          :headers="users_header"
          :items="users"
          item-key="userId"
          :search.sync="user_search"
          :sort-by="[]"
          :sort-desc="[]"
          multi-sort
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="allowEdit"
              small
              depressed
              class="ml-2"
              color="success"
              @click="onUserEdit(item)"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              v-if="allowDelete"
              class="ml-2"
              outlined
              small
              color="error"
              @click="onUserDelete(item)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </base-material-card>
    </v-container>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import userAPI from '@/services/userServices'
  import verifyAPI from '@/authentication/authorization'
  import i18n from '@/i18n'
  function initialState () {
    return {
      appTitle: i18n.t('titleUsers'),
      breadcrumbs: [
        {
          text: i18n.t('bcHome'),
          disabled: false,
          href: '/dashboard',
        },
        {
          text: i18n.t('bcAdmin'),
          disabled: true,
          href: '/admin',
        },
        {
          text: i18n.t('bcUsers'),
          disabled: true,
          href: '/admin/users',
        },
      ],
      allowEdit: false,
      allowDelete: false,
      showErrorDialog: false,
      errorMessage: '',
      environment: process.env.VUE_APP_ENVIRONMENT,
      modelProcessing: false,
      user_search: undefined,
      users: [],
      users_header: [
        {
          text: i18n.t('colUserName'),
          value: 'name',
          class: 'primary lighten-4 display-1 font-weight-bold',
        },
        {
          text: i18n.t('colUserPhone'),
          value: 'mobilePhone',
          width: '12%',
          class: 'primary lighten-4 display-1 font-weight-bold',
        },
        {
          text: i18n.t('colUserEMail'),
          value: 'eMail',
          width: '20%',
          class: 'primary lighten-4 display-1 font-weight-bold',
        },
        {
          text: i18n.t('colUserPartner'),
          value: 'partnerName',
          width: '20%',
          class: 'primary lighten-4 display-1 font-weight-bold',
        },
        {
          text: i18n.t('colActions'),
          value: 'actions',
          width: '10%',
          class: 'primary lighten-4 display-1 font-weight-bold',
        },
      ],
    }
  }
  export default {
    Name: 'Users',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    data: function () {
      return initialState()
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
    },
    mounted: function () {
      this.modelProcessing = true
      if (verifyAPI.isAuthorized(this.currentUser, 2)) {
        this.allowEdit = true
        this.allowDelete = true
      }
      userAPI.getApplicationUsers()
        .then(response => {
          this.users = response.data
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
    },
    methods: {
      onUserEdit: function (item) {
        this.$router.push(`/admin/users/userdetails/${item.userId}`)
      },
      onUserDelete: function (item) {
        const msg = `${this.$t('confirmUserDisable')} ${item.name}.\n${this.$t('confirmContinue')}`
        if (confirm(msg)) {
          this.modelProcessing = true
          userAPI.disableUser(item.userId)
            .then(response => {
              alert(this.$t('alertUserDisabled'))
              this.modelProcessing = false
            })
            .catch(error => {
              this.showErrorDialog = true
              this.errorMessage = error.message
              this.modelProcessing = false
            })
        }
      },
    },
  }
</script>
